import { Autocomplete } from "@material-ui/lab";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { ArrowRightAlt } from "@material-ui/icons";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  customTextField: {
    "& input::label": {
      fontStyle: "italic",
    },
  },
});

const AccStageTrend = (props) => {
  const classes = useStyles();

  const { isSubFilter } = props;

  const getDateValue = (option) => {
    let newFilterAttribute = { ...props.filterAttribute };
    if ("values" in newFilterAttribute) {
      return newFilterAttribute["values"][0]?.trendValues[option];
    }
  };

  function convertUTCDateToLocalDate(date) {
    date.setHours(0);
    date.setMinutes(0);
    let offset = date.getTimezoneOffset() * 60 * 1000;
    let timeInEpoch = date.getTime();
    if (timeInEpoch - offset > 0)
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return date;
  }

  const getYesterdaysDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date.toISOString().split("T")[0];
  };

  const dateFieldsOnchange = (value, option) => {
    let newFilterAttribute = { ...props.filterAttribute };
    if ("values" in newFilterAttribute) {
      if (moment(value).isValid()) {
        value = convertUTCDateToLocalDate(value);
        newFilterAttribute["values"][0].trendValues[option] =
          value.toISOString();
      }
    }
    props.onAttributeChange(newFilterAttribute, "others");
  };

  const onChangeRelationAccStage = (valueObj, option) => {
    console.log("line no 669 valueObj", valueObj);
    const newFilterAttribute = { ...props.filterAttribute };

    if (option === 0) {
      newFilterAttribute["relation"] = valueObj.value;
    } else {
      if ("values" in newFilterAttribute) {
        if (option === 1) {
          newFilterAttribute["values"][0].trend = valueObj.value;
        } else if (option === 2) {
          newFilterAttribute["values"][0].trendRelation = valueObj.value;
          if (valueObj.value === "in the past") {
            newFilterAttribute["values"][0].trendValues[0] = "Month";
            newFilterAttribute["values"][0].trendValues[1] = null;
          }
        } else if (option === 3) {
          newFilterAttribute["values"][0].trendValues[0] = valueObj.value;
        }
      }
    }
    const date2 = new Date();
    if (valueObj.value === "before" || valueObj.value === "after") {
      dateFieldsOnchange(date2, 0);
      newFilterAttribute["values"][0].trendValues[1] = null;
    }
    if (valueObj.value === "between") {
      const date1 = new Date();
      date1.setDate(date1.getDate() - 1);
      dateFieldsOnchange(date1, 0);
      dateFieldsOnchange(date2, 1);
    }
    props.onAttributeChange(newFilterAttribute, "others");
  };

  const getDropdownOptions = (option) => {
    const dropdown0 = [
      { title: "is", value: "is" },
      { title: "is not", value: "is not" },
    ];
    const dropdown1 = [
      { title: "upwards", value: "upwards" },
      { title: "downwards", value: "downwards" },
      { title: "constant", value: "constant" },
    ];
    const dropdown2 = [
      { title: "in the past", value: "in the past" },
      { title: "is between", value: "between" },
      { title: "before", value: "before" },
      { title: "after", value: "after" },
    ];
    const dropdown3 = [
      { title: "Week", value: "Week" },
      { title: "Month", value: "Month" },
      { title: "Quarter", value: "Quarter" },
      { title: "Year", value: "Year" },
      {title:"2 Months", value: "2 Months"},
      {title:"2 Weeks", value: "2 Weeks"},
      {title:"1 Day", value: "1 Day"}
    ];
    if (option === 0) {
      return dropdown0;
    } else if (option === 1) {
      return dropdown1;
    } else if (option === 2) {
      return dropdown2;
    } else {
      return dropdown3;
    }
  };
  const getDropdownValues = (val, option) => {
    const dropdown = getDropdownOptions(option);
    for (const obj of dropdown) {
      if (obj.value === val) {
        return obj;
      }
    }
  };

  return (
    <div
      style={{
        width: isSubFilter ? "584px" : "618px",
        marginTop: "1px",
        display: "grid",
        gap: "10px",
      }}
    >
      <div
        style={{ display: "grid", gridTemplateColumns: "50% 50%", gap: "10px" }}
      >
        {"attribute" in props.filterAttribute && (
          <Autocomplete
            popupIcon={
              <KeyboardArrowDownRoundedIcon
                style={{ color: "rgba(3, 3, 3, 0.6)" }}
              />
            }
            defaultValue={{ title: "is", value: "is" }}
            value={getDropdownValues(props.filterAttribute.relation, 0)}
            disableClearable
            onChange={(event, newValue) => {
              onChangeRelationAccStage(newValue, 0);
            }}
            options={getDropdownOptions(0)}
            getOptionLabel={(option) => option.title}
            style={{ minWidth: "100%" }}
            renderInput={(params) => (
              <TextField
                classes={{ root: classes.customTextField }}
                {...params}
                variant="outlined"
              />
            )}
          />
        )}

        {"values" in props.filterAttribute && (
          <Autocomplete
            popupIcon={
              <KeyboardArrowDownRoundedIcon
                style={{ color: "rgba(3, 3, 3, 0.6)" }}
              />
            }
            defaultValue={{ title: "upwards", value: "upwards" }}
            value={getDropdownValues(
              props.filterAttribute?.values[0]?.trend,
              1
            )}
            disableClearable
            onChange={(event, newValue) => {
              onChangeRelationAccStage(newValue, 1);
            }}
            options={getDropdownOptions(1)}
            getOptionLabel={(option) => option.title}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                classes={{ root: classes.customTextField }}
                {...params}
                variant="outlined"
              />
            )}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "10px",
          width: "100%",
          gap: "10px",
        }}
      >
        {"values" in props.filterAttribute && (
          <Autocomplete
            popupIcon={
              <KeyboardArrowDownRoundedIcon
                style={{ color: "rgba(3, 3, 3, 0.6)" }}
              />
            }
            defaultValue={{ title: "in the past", value: "in the past" }}
            value={getDropdownValues(
              props.filterAttribute?.values[0]?.trendRelation,
              2
            )}
            disableClearable
            onChange={(event, newValue) => {
              onChangeRelationAccStage(newValue, 2);
            }}
            options={getDropdownOptions(2)}
            getOptionLabel={(option) => option.title}
            style={{ width: "50%" }}
            renderInput={(params) => (
              <TextField
                classes={{ root: classes.customTextField }}
                {...params}
                variant="outlined"
              />
            )}
          />
        )}

        {"values" in props.filterAttribute &&
        props.filterAttribute?.values[0]?.trendRelation === "in the past" ? (
          <Autocomplete
            popupIcon={
              <KeyboardArrowDownRoundedIcon
                style={{ color: "rgba(3, 3, 3, 0.6)" }}
              />
            }
            defaultValue={{ title: "Week", value: "Week" }}
            value={getDropdownValues(
              props.filterAttribute?.values[0]?.trendValues[0],
              3
            )}
            disableClearable
            onChange={(event, newValue) => {
              onChangeRelationAccStage(newValue, 3);
            }}
            options={getDropdownOptions(3)}
            getOptionLabel={(option) => option.title}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                classes={{ root: classes.customTextField }}
                {...params}
                variant="outlined"
              />
            )}
          />
        ) : (
          <>
            {props.filterAttribute.values[0]?.trendRelation !== "between" ? (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    maxDate={new Date().toISOString().split("T")[0]}
                    value={getDateValue(0)}
                    style={{
                      flexGrow: "1",
                      height: "40px",
                    }}
                    InputAdornmentProps={{ position: "end" }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                        height: 40,
                      },
                    }}
                    onChange={(newValue) => {
                      dateFieldsOnchange(newValue, 0);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexGrow: "1",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    maxDate={getYesterdaysDate()}
                    value={getDateValue(0)}
                    style={{ minwidth: "100px", width: "50%" }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                        height: 40,
                      },
                    }}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(newValue) => {
                      dateFieldsOnchange(newValue, 0);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <div
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 10px",
                    }}
                  >
                    <ArrowRightAlt />
                  </div>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    minDate={props.filterAttribute["values"][0]?.trendValues[0]}
                    maxDate={new Date().toISOString().split("T")[0]}
                    value={getDateValue(1)}
                    style={{ minwidth: "100px", width: "50%" }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                        height: 40,
                      },
                    }}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(newValue) => {
                      dateFieldsOnchange(newValue, 1);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AccStageTrend;
