import ExportFilterCriteriaSection from "./ExportFilterCriteriaSection";
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from "react";
import { Radio, RadioGroup, TextField, Tooltip, Typography } from "@material-ui/core";
import "./ExportFilterCriteria.css"
import { Info } from "@material-ui/icons";
import { useAuthState } from "@bamboobox/b2logincheck";
import { HashRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@material-ui/core'
import UItheme from "@bamboobox/b2-theme";
import _ from "lodash"

import getTenantConfigJSON from "./getTenantConfigJson";

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    root: {
      border: "none"
    },
    contained: {
      backgroundColor: "primary"
    }
  })

  const relationArrConfig = {
    "NUMERIC": [
        "is equal (=)",
        "greater than(>)",
        "less than(<)",
        "between",
        "is empty",
        "is not empty"
    ],
    "BOOLEAN": ["true", "false"],
    "DATE": [
        "is equal (=)",
        "before",
        "after",
        "between",
        "is more than",
        "in the past",
        "is empty",
        "is not empty",
    ],
    "TEXT": [
        "includes",
        "excludes",
        "contains",
        "does not contain",
        "starts with",
        "ends with",
        "is empty",
        "is not empty",
    ],
    "PICKLIST": [
        "includes",
        "excludes",
        "is empty",
        "is not empty"
    ],
    "DEFAULT": [
        "includes",
        "excludes",
        "contains",
        "does not contain",
        "starts with",
        "ends with",
        "is empty",
        "is not empty",
    ],
    "pastValues": ["Week", "Month", "Quarter", "Year","2 Weeks", "2 Months", "1 Day"],
  }

export const ExportFilterCriteria = (props) => {
    const classes = useStyles()

    const [subfiltersConfig, setsubfiltersConfig] = useState({});

    console.log('props are',props)
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    let params_icp = query.get("icp") || "";

    const getFilterAttributesArray = () => {
        if (props.criteriaBody === null) {
            return [[{}]];
        }
        else {
            return props.criteriaBody;
        }
    }
    const getIsTitleDisabled = () => {
        if (props.criteriaName === undefined) {
            return false;
        }
        else {
            return true;
        }
    }
    const getTitleValue = () => {
        if (props.criteriaName === null) {
            return null;
        }
        else {
            return props.criteriaName;
        }
    }
    // useEffect(()=>{
    //     props.setTitle(props.criteriaName)
    // },[props.criteriaName])
    // useEffect(()=>{
    //     props.setFilterAttributesArray(getFilterAttributesArray)
    // },[props.criteriaBody])
    // const [titleDisable, setTitleDisable] = useState(false);
    // const [openDialog, setOpenDialog] = useState(false);
    ////////////////////
    // parent should send the type as prop, now hard coded in ExportFilter component
    // const [exportType, setExportType] = useState("CONTACT");
    // const [exportType, setExportType] = useState(props.exportType || 'CONTACT');
    /////////////////////
    const tenantId = (useAuthState()).user.tenantId;
    console.log("filterAttributesArray");
    console.log(props.filterAttributesArray);
    

    useEffect(()=>{
        props.setExpType(props.expType || "Contact")
    },[props.expType])


//////////////////////
//use effect should be edited based on account api
//////////////////////

    useEffect(() => {
        // console.log("filterAttributesArrayBody->>",filterAttributesArrayBody); 
        console.log("props", props.icp)
        let active = true;
        //params_icp
        // let temp_icp = 'capillarytest03june'
        let CDP_API_URL = props.expType==='Contact'? `${window._env_.UNIFIED_URL}/contact/new-list/${tenantId}/filter?page=1&limit=10&icp=${params_icp}&sortby=&sortType=`:
        `${window._env_.UNIFIED_URL}/account/new-list/${tenantId}/filter?page=1&limit=10&icp=${params_icp}&sortby=&sortType=`;
        const filterAttributesArrayBody = [];

        console.log("checking props.filterAttributesArray",props.filterAttributesArray)
        if(props.filterAttributesArray?.length>0){
        const newFilterAttributesArray = [...props.filterAttributesArray];
        for (const filterAttributes of newFilterAttributesArray) {
            if (filterAttributes.length > 0) {
                const filterAttributesBody = [];
                for (const filterAttribute of filterAttributes) {
                    if ("values" in filterAttribute) {
                        filterAttributesBody.push(filterAttribute);
                    }
                }
                if (filterAttributesBody.length > 0) {
                    filterAttributesArrayBody.push(filterAttributesBody);
                }
            }
        }
        }
        console.log("filterAttributesArrayBody");
        console.log("checking filterAttributesArrayBody",filterAttributesArrayBody);
    
        if (filterAttributesArrayBody.length > 0) {

            console.log("checking2 entering if", filterAttributesArrayBody);

            const requestOptions = {
                credentials: 'include',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({filters: filterAttributesArrayBody, columns: []})
            } ;
            const newResultObjects = { ...props.resultObjects };
            console.log(props.resultObjects)
            newResultObjects.contactCount = '-';
            newResultObjects.filter = {};
            if(props.resultObjects!==undefined){
                console.log("checking res and new - 119",props.resultObjects,newResultObjects)
                props.setResultObjects(newResultObjects)
            }
                fetch(CDP_API_URL, requestOptions)
                .then((result) => result.json())
                .then((jsonResult) => {
                    if (jsonResult.success) {
                        if(active){
                            if(props.resultObjects!==undefined){
                                console.log("checking res and new - 128",props.resultObjects,newResultObjects)
                                props.setResultObjects({ totalContactCount: props.resultObjects.totalContactCount, contactCount: jsonResult.filteredCount, filter: { filterBody: filterAttributesArrayBody, filterType: props.expType }, title: props.title });
                                console.log("checking res and new - 135",props.resultObjects,newResultObjects,filterAttributesArrayBody)
                                // props.setResultObjects({ totalContactCount: 346, contactCount: 1, filter: { filterBody: filterAttributesArrayBody, filterType: exportType }, title: title });
                            }
                        }
                        console.log("jsonResult");
                        console.log(jsonResult);
                    }
                    else {
                        console.log(jsonResult);
                    }
                })
        }
        else {
            console.log("checking2 entering else")
                const newResultObjects = { ...props.resultObjects };
                console.log("checking res",props.resultObjects)
                newResultObjects.contactCount = '-';
                newResultObjects.filter = {};
                console.log("checking new",newResultObjects)
            if(props.resultObjects!==undefined){
                props.setResultObjects(newResultObjects)
            }
            
        }
        return ()=>{
            active = false;
        }
    }, [props.filterAttributesArray, props.icp]);

    const getSubfiltersConfig = async () => {
    if (Object.keys(subfiltersConfig).length === 0) {
        const configResp = await getTenantConfigJSON(tenantId, "filters", [
            "subfiltersConfig",
        ]);

        if (configResp?.length > 0) {
            setsubfiltersConfig(configResp[0]?.configVal);
        }
        return;
    }
    };
    getSubfiltersConfig();

    const textFieldsOnchange = (value) => {
        const newResultObjects = { ...props.resultObjects };
        newResultObjects["title"] = value;
        props.setTitle(value);
        if(props.resultObjects!==undefined){
        props.setResultObjects(newResultObjects)
        }
    }

   
   
 
    

    return (
        <div id="export-filter-criteria">
            <div id="export-filter-criteria-basic">
             
                
            </div>
            <div id="scroll">
            {
                props.filterAttributesArray?.map((filterAttributes,filterAttributesIndex) => (
                    <ExportFilterCriteriaSection  
                        key={`filterAttributes${filterAttributesIndex}`}
                        filterAttributesArray={props.filterAttributesArray}
                        filterAttributesIndex={filterAttributesIndex}
                        filterAttributes={filterAttributes}
                        setFilterAttributesArray={props.setFilterAttributesArray} 
                        icp={props.icp}
                        exportType = {props.expType}
                        relationArrConfig={relationArrConfig}
                        subfiltersConfig={subfiltersConfig}
                    />
                ))
            }
            </div>
         
       
       
        </div>
       
    );
}

export default ExportFilterCriteria;